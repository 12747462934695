import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../context/AuthContext';
import usePermission from '../../../../hooks/usePermission';
import useApi from '../../../../hooks/useApi';
import useConfirm from '../../../../hooks/useConfirm';
import useAlert from '../../../../hooks/useAlert';
import { toStringWithComma } from '../../../../utils/string';

function Download(props) {
  // 認証情報
  const userCredential = useContext(AuthContext);
  const { isAdminUser } = usePermission();

  const navigate = useNavigate();

  const [deleteCount, setDeleteCount] = useState(0);

  const confirmDelete = useConfirm();
  const alertDeleteSuccess = useAlert();

  const { downloadCsv, downloadPdf, deletePurchasedLocation } = useApi();

  const handleDownloadPdf = async () => {
    props.openPageLoader(true);
    try {
      await downloadPdf({
        'user_permission': `${userCredential.user_permission}`,
        'company_id': userCredential.company_id,
        'scenario': props.scenario,
        'analysis_id_list': props.analysisIds.current,
        'location_name_list': props.locationNames.current,
        'group_id_list': props.groupIds.current,
        'user_id': `${userCredential.user_id}`
      });
    } catch {
      props.openPageLoader(false);
      await props.openServerError();
      return;
    }
    props.openPageLoader(false);
  };

  const handleClickDownloadCsv = async () => {
    // 3ファイル出力
    if (!(await handleDownloadCsv('depth'))) return;
    if (!(await handleDownloadCsv('damage'))) return;
    if (!(await handleDownloadCsv('affected_day'))) return;
  };

  const handleDownloadCsv = async (download_result) => {
    props.openPageLoader(true);
    try {
      await downloadCsv({
        'user_permission': `${userCredential.user_permission}`,
        'company_id': userCredential.company_id,
        'analysis_id_list': props.analysisIds.current,
        'location_name_list': props.locationNames.current,
        'group_id_list': props.groupIds.current,
        'user_id': `${userCredential.user_id}`,
        'download_result': download_result
      });
    } catch (error) {
      props.openPageLoader(false);
      if (typeof error === 'string') {
        await props.openError(error);
      } else {
        await props.openServerError();
      }
      return false;
    }
    props.openPageLoader(false);
    return true;
  };

  // Delete Resultボタン押下時処理
  const handleClickDeleteLocation = async () => {
    // 件数確認
    const resCheck = await handleDeleteLocation('0');
    if (!resCheck) {
      return;
    }
    setDeleteCount(resCheck.delete_count);

    // 削除確認ダイアログ表示
    if (!(await confirmDelete.open())) {
      return;
    }

    // 削除実行
    const resDelete = await handleDeleteLocation('1');
    if (!resDelete) {
      return;
    }
    setDeleteCount(resCheck.delete_count);

    // 削除完了ダイアログ表示
    await alertDeleteSuccess.open();

    // 画面リロード
    navigate(0);
  };

  // 拠点削除API実行
  const handleDeleteLocation = async (do_delete) => {
    // PageLoader表示
    props.openPageLoader(true);

    let result;
    try {
      // API実行
      result = await deletePurchasedLocation({
        'do_delete': do_delete,
        'user_permission': `${userCredential.user_permission}`,
        'company_id': userCredential.company_id,
        'analysis_id_list': props.analysisIds.current,
        'location_name_list': props.locationNames.current,
        'group_id_list': props.groupIds.current
      });
    } catch (error) {
      // サーバーエラー
      props.openPageLoader(false);
      await props.openServerError();
      return;
    }
    if (result.err_message) {
      // 件数エラー
      props.openPageLoader(false);
      await props.openError(result.err_message);
      return;
    }
    props.openPageLoader(false);
    return result;
  };

  // 削除確認ダイアログ：削除対象件数
  const createDeleteCount = () => {
    const isAllSelected =
      props.analysisIds.current.length === 0 &&
      props.locationNames.current.length === 0 &&
      props.groupIds.current.length === 0;
    return (
      <p className="mb-2" style={{ marginLeft: '32px' }}>
        削除対象件数：{isAllSelected ? 'All items' : toStringWithComma(deleteCount)}
        <br />
        Number of items to delete
      </p>
    );
  };

  // 削除確認ダイアログ：削除対象Analysis ID
  const createAnalysisIdList = () => {
    const targetItems = props.fullAnalysisIds.current;

    if (!targetItems || targetItems.length === 0) {
      return null;
    }

    const displayItems = targetItems.slice(0, 4);
    return (
      <div>
        <ul style={{ marginLeft: '32px' }}>
          {displayItems.map((item, index) => (
            <li key={index} className="m-0">
              {item}
            </li>
          ))}
        </ul>
        {targetItems.length > 4 ? (
          <p className="my-2">
            上記を含め、計{targetItems.length}件が削除対象Analysis IDとなります。
            <br />
            Including the Analysis IDs listed above, {targetItems.length} Analysis IDs will be deleted.
          </p>
        ) : null}
      </div>
    );
  };

  return (
    <>
      <div className="sticky-buttons">
        {isAdminUser() ? (
          <button
            className="btn btn-lg rounded-pill px-4 delete-location"
            disabled={props.isResultEmpty}
            onClick={handleClickDeleteLocation}>
            Delete Result
          </button>
        ) : null}
        <button
          className="btn btn-lg btn-primary rounded-pill pe-4"
          disabled={props.isResultEmpty}
          onClick={handleDownloadPdf}>
          <span className="icon icon-pdf"></span>Download PDF
        </button>
        <button
          className="btn btn-lg btn-primary rounded-pill pe-4"
          disabled={props.isResultEmpty}
          onClick={handleClickDownloadCsv}>
          <span className="icon icon-csv"></span>Download CSV
        </button>
      </div>

      <confirmDelete.Confirm yes="削除する Delete" no="Cancel">
        <p className="mb-2">
          選択されたデータを削除しますか？
          <br />
          Are you sure you want to delete the selected data?
        </p>
        {createDeleteCount()}
        <p className="mb-2" style={{ marginLeft: '32px' }}>
          削除対象Analysis ID：
          <br />
          Analysis IDs to delete
        </p>
        {createAnalysisIdList()}
        <p className="fw-bolder my-2" style={{ color: 'red' }}>
          ※この操作は取り消すことができません。
          <br />
          *This action cannot be undone.
        </p>
        <p className="mb-2">
          よろしければ「削除する」をクリックしてください。
          <br />
          Click "Delete" to proceed.
        </p>
      </confirmDelete.Confirm>

      <alertDeleteSuccess.Alert>
        削除が完了しました。
        <br />
        Deletion completed.
        <br />
        <br />
        削除件数：{toStringWithComma(deleteCount)}
        <br />
        Number of items deleted
      </alertDeleteSuccess.Alert>
    </>
  );
}

export default Download;
