const PanelItems = {
  // ハザードマップ
  HazardMap: {
    yearScenario: {
      HazardMap1_1: { value: 'current', checked: true },
      HazardMap1_2: { value: '2080, SSP5-8.5', checked: false }
    }
  },

  // 洪水頻度変化予測
  FloodPrediction: {
    yearScenario: {
      FloodPrediction1_1: { value: '2080, SSP1-2.6', checked: false },
      FloodPrediction1_2: { value: '2080, SSP5-8.5', checked: false }
    }
  },

  // 高解像度ハザードマップ
  HazardMapHiRes: {
    recurrencePeriod: {
      HazardMapHiRes1_1: { value: '100years', checked: false },
      HazardMapHiRes1_2: { value: '1000years', checked: false }
    },
    yearScenario: {
      HazardMapHiRes2_1: { value: 'current', checked: false },
      HazardMapHiRes2_2: { value: '2050, SSP1-2.6', checked: false },
      HazardMapHiRes2_3: { value: '2050, SSP5-8.5', checked: false },
      HazardMapHiRes2_4: { value: '2080, SSP1-2.6', checked: false },
      HazardMapHiRes2_5: { value: '2080, SSP5-8.5', checked: false }
    }
  },

  // 金融版高解像度ハザードマップ
  FinancialHiRes: {
    recurrencePeriod: {
      FinancialHiRes1_1: { value: '100years', checked: true },
      FinancialHiRes1_2: { value: '1000years', checked: false }
    },
    yearScenario: {
      FinancialHiRes2_1: { value: 'current', checked: true },
      FinancialHiRes2_2: { value: '2050, SSP1-2.6', checked: false },
      FinancialHiRes2_3: { value: '2050, SSP5-8.5', checked: false },
      FinancialHiRes2_4: { value: '2080, SSP1-2.6', checked: false },
      FinancialHiRes2_5: { value: '2080, SSP5-8.5', checked: false }
    },
    riseScenario: {
      FinancialHiRes3_1: { value: '2.0°C rise', checked: false },
      FinancialHiRes3_2: { value: '3.0°C rise', checked: false },
      FinancialHiRes3_3: { value: '4.0°C rise', checked: false }
    }
  }
};

export default PanelItems;
