import { useContext, useEffect, useState, useRef } from 'react';
import ComboBox from './ComboBox';
import usePermission from '../../../../hooks/usePermission';
import useApi from '../../../../hooks/useApi';
import { AuthContext } from '../../../../context/AuthContext';

function ScenarioSelector(props) {
  const userCredential = useContext(AuthContext);

  const { isPremiumUser, isFinancialUser } = usePermission();
  const { getAnalysisIdList, getLocationNameList, getGroupIdList } = useApi();

  const [selectedAnalysisIds, setSelectedAnalysisIds] = useState([]);
  const [analysisIds, setAnalysisIds] = useState([]);

  const [selectedLocationNames, setSelectedLocationNames] = useState([]);
  const [locationNames, setLocationNames] = useState([]);

  const [selectedGroupIds, setSelectedGroupIds] = useState([]);
  const [groupIds, setGroupIds] = useState([]);

  const filterRef = useRef(null);
  const [scenarioHeight, setScenarioHeight] = useState(0);

  useEffect(() => {
    setScenarioHeight(filterRef.current.clientHeight);
  }, []);

  // 初期表示
  useEffect(() => {
    (async () => {
      props.openPageLoader(true);
      let res1;
      try {
        res1 = await getAnalysisIdList({ company_id: userCredential.company_id });
      } catch {
        props.openPageLoader(false);
        await props.openServerError();
        return;
      }
      props.openPageLoader(false);

      setAnalysisIds(
        res1.analysis_id_list.map((x) => {
          return { label: x, value: x };
        })
      );
      handleChangeAnalysisIds(res1.analysis_id_list);
    })();
  }, []);

  // Analysis ID選択時
  const handleChangeAnalysisIds = (checkedValues) => {
    if (analysisIds.length === 0) {
      return;
    }
    const analysisIdList = analysisIds.length === checkedValues.length ? [] : checkedValues;

    props.openPageLoader(true);
    (async () => {
      // 有償版ユーザー：Location Nameを再取得する
      if (isPremiumUser()) {
        let res2;
        try {
          res2 = await getLocationNameList({ company_id: userCredential.company_id, analysis_id_list: analysisIdList });
        } catch {
          props.openPageLoader(false);
          await props.openServerError();
          return;
        }
        props.openPageLoader(false);
        setLocationNames(
          res2.location_name_list.map((x) => {
            return { label: x, value: x };
          })
        );
      }
      // 金融版ユーザー：Group IDを再取得する
      if (isFinancialUser()) {
        let res3;
        try {
          res3 = await getGroupIdList({ company_id: userCredential.company_id, analysis_id_list: analysisIdList });
        } catch {
          props.openPageLoader(false);
          await props.openServerError();
          return;
        }
        props.openPageLoader(false);
        setGroupIds(
          res3.group_id_list.map((x) => {
            return { label: x, value: x };
          })
        );
      }
    })();

    // 状態の更新
    setSelectedAnalysisIds(checkedValues);
  };

  const handleChangeLocationNames = (checkedValues) => {
    setSelectedLocationNames(checkedValues);
  };

  const handleChangeGroupIds = (checkedValues) => {
    setSelectedGroupIds(checkedValues);
  };

  const [selectedScenario, setSelectedScenario] = useState('ssp126');
  const handleChangeScenario = (e) => {
    const value = e.target.value;
    props.onChangeScenario(value);
    setSelectedScenario(value);
  };

  // Filterボタン押下時処理
  const handleClickFilter = () => {
    // 全件選択の場合は[]を渡す
    const analysisIdList = analysisIds.length === selectedAnalysisIds.length ? [] : selectedAnalysisIds;
    const locationNameList = locationNames.length === selectedLocationNames.length ? [] : selectedLocationNames;
    const groupIdList = groupIds.length === selectedGroupIds.length ? [] : selectedGroupIds;
    // Analysis IDが全件選択／全件未選択の場合は全件を渡す（削除確認ダイアログ表示用）
    const fullAnalysisIdList = analysisIdList.length === 0 ? analysisIds.map((x) => x.value) : analysisIdList;

    props.onChangeFilter(analysisIdList, locationNameList, groupIdList, fullAnalysisIdList);
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="bg-primary border-0 p-2" style={{ height: scenarioHeight }}>
            <div className="card h-100 border-0">
              <div className="card-body h-100">
                <div className="d-flex gap-5 align-items-center h-100">
                  <span className=" fw-bolder">Scenario</span>
                  <div>
                    <div className="form-check form-check-inline small mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="ssp126"
                        checked={selectedScenario === 'ssp126'}
                        name="radio1"
                        id="radio1-1"
                        onChange={handleChangeScenario}
                      />
                      <label className="form-check-label" htmlFor="radio1-1">
                        SSP1-2.6
                      </label>
                    </div>
                    <div className="form-check form-check-inline small mb-0">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="ssp585"
                        checked={selectedScenario === 'ssp585'}
                        name="radio1"
                        id="radio1-2"
                        onChange={handleChangeScenario}
                      />
                      <label className="form-check-label" htmlFor="radio1-2">
                        SSP5-8.5
                      </label>
                    </div>
                    {isFinancialUser() && (
                      <div className="form-check form-check-inline small mb-0">
                        <input
                          className="form-check-input"
                          type="radio"
                          value="temp_x"
                          checked={selectedScenario === 'temp_x'}
                          name="radio1"
                          id="radio1-3"
                          onChange={handleChangeScenario}
                        />
                        <label className="form-check-label" htmlFor="radio1-3">
                          X°C rise
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="bg-primary p-2 mb-4" ref={filterRef}>
            <div className="card border-0">
              <div className="card-body">
                <div className="d-flex gap-5 align-items-center">
                  <div className="location-filter-panel">
                    <div className="analysis-id">
                      <span className="fw-bolder fs-small">Analysis ID</span>
                      <ComboBox
                        id="cmb1"
                        title="Analysis ID"
                        items={analysisIds}
                        onChange={handleChangeAnalysisIds}></ComboBox>
                    </div>
                    {isPremiumUser() && (
                      <div className="location-name">
                        <span className="fw-bolder fs-small">Location Name</span>
                        <ComboBox
                          id="cmb2"
                          title="Location Name"
                          items={locationNames}
                          onChange={handleChangeLocationNames}></ComboBox>
                      </div>
                    )}
                    {isFinancialUser() && (
                      <div className="group-id">
                        <span className="fw-bolder fs-small">Group ID</span>
                        <ComboBox
                          id="cmb3"
                          title="Group ID"
                          items={groupIds}
                          onChange={handleChangeGroupIds}></ComboBox>
                      </div>
                    )}
                    <a className="btn btn-primary" onClick={handleClickFilter}>
                      Filter
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ScenarioSelector;
