import { createContext, useEffect, useState } from 'react';
import { useTokenContext } from './TokenContext';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const { accessToken } = useTokenContext();
  const [userCredential, setUserCredential] = useState(null);

  let userCredentialTemp;

  useEffect(() => {
    if (!userCredentialTemp) return;
    setUserCredential(userCredentialTemp);
  });

  // 全情報取得完了後にレンダリング
  if (userCredential) {
    return <AuthContext.Provider value={userCredential}>{children}</AuthContext.Provider>;
  }

  if (parseInt(process.env.REACT_APP_AUTH0)) {
    if (!accessToken) {
      return <AuthContext.Provider value={userCredential}>{children}</AuthContext.Provider>;
    }
    // 認証基盤接続あり
    const text = accessToken.text;
    const tenant = text['https://auth.irric.co.jp/tenant'];
    const user = text['https://auth.irric.co.jp/user'];
    const metadata = text['https://auth.irric.co.jp/metadata'];

    const company_name = tenant.display_name.substring(0, 40);
    const family_name = user.family_name.substring(0, 20);
    const given_name = user.given_name.substring(0, 20);

    userCredentialTemp = {
      company_id: tenant.tenant_id,
      company_name: company_name,
      user_id: user.tenant_user_id,
      user_name: `${family_name} ${given_name}`,
      family_name: family_name,
      given_name: given_name,
      user_type_code: `${metadata.TENANT_FIELD.user_permission}${metadata.APP_FIELD.admin_permission}`,
      user_permission: metadata.TENANT_FIELD.user_permission,
      admin_permission: metadata.APP_FIELD.admin_permission
    };
  } else {
    // 認証基盤接続なし
    if (!sessionStorage.getItem('userPermission')) {
      const userPermission = prompt('free: 0 or premium: 1 or financial: 2');
      sessionStorage.setItem('userPermission', userPermission);
    }

    if (!sessionStorage.getItem('adminPermission')) {
      const adminPermission = prompt('nomal: 0 or admin: 1');
      sessionStorage.setItem('adminPermission', adminPermission);
    }

    switch (sessionStorage.getItem('userPermission')) {
      case '1':
        userCredentialTemp = premium.userCredential;
        break;
      case '2':
        userCredentialTemp = financial.userCredential;
        break;
      default:
        userCredentialTemp = free.userCredential;
        break;
    }

    userCredentialTemp.admin_permission = sessionStorage.getItem('adminPermission');
  }
};

// 認証基盤接続なし用：無償ユーザー
const free = {
  userCredential: {
    company_id: 'TEST_C00',
    company_name: '株式会社無償',
    user_id: 'test_u00',
    user_name: '無償 太郎',
    family_name: '無償',
    given_name: '太郎',
    user_type_code: '00',
    // 無償ユーザー：'0', 有償ユーザー：'1'
    user_permission: '0',
    // 一般ユーザー：'0', 管理者ユーザー：'1'
    admin_permission: '0'
  }
};

// // 認証基盤接続なし用：有償ユーザー
// const premium = {
//   userCredential: {
//     company_id: 'TEST_C01',
//     company_name: '株式会社有償',
//     user_id: 'test_u01',
//     user_name: '有償 太郎',
//     family_name: '有償',
//     given_name: '太郎',
//     user_type_code: '10',
//     // 無償ユーザー：'0', 有償ユーザー：'1'
//     user_permission: '1',
//     // 一般ユーザー：'0', 管理者ユーザー：'1'
//     admin_permission: '0'
//   }
// };

// // 認証基盤接続なし用：金融ユーザー
// const financial = {
//   userCredential: {
//     company_id: 'TEST_F01',
//     company_name: '株式会社金融',
//     user_id: 'test_u02',
//     user_name: '金融 太郎',
//     family_name: '金融',
//     given_name: '太郎',
//     user_type_code: '20',
//     // 無償ユーザー：'0', 有償ユーザー：'1'
//     user_permission: '2',
//     // 一般ユーザー：'0', 管理者ユーザー：'1'
//     admin_permission: '0'
//   }
// };

const premium = {
  userCredential: {
    company_id: 'TEST_C12',
    // company_id: '90652175-fff2-4589-bb93-d01bd780bb7f',
    company_name: '株式会社有償12',
    user_id: 'test_matsuyama',
    user_name: '松山 テスト',
    family_name: '松山',
    given_name: 'テスト',
    user_type_code: '11',
    // 無償ユーザー：'0', 有償ユーザー：'1'
    user_permission: '1',
    // 一般ユーザー：'0', 管理者ユーザー：'1'
    admin_permission: '1'
  }
};


const financial = {
  userCredential: {
    company_id: 'aiueo_company',
    company_name: 'あいうえお',
    user_id: 'aiueo_user',
    user_name: 'あいうえおかきくけこさしすせそたちつてと 太郎',
    family_name: 'あいうえおかきくけこさしすせそたちつてと',
    given_name: '太郎',
    user_type_code: '20',
    // 無償ユーザー：'0', 有償ユーザー：'1'
    user_permission: '2',
    // 一般ユーザー：'0', 管理者ユーザー：'1'
    admin_permission: '0'
  }
};


// const premium = {
//   userCredential: {
//     company_id: 'DELETE_TEST_P01',
//     // company_id: '90652175-fff2-4589-bb93-d01bd780bb7f',
//     company_name: '削除テスト有償01',
//     user_id: 'delete_test_01',
//     user_name: '削除用 テスト01',
//     family_name: '削除用',
//     given_name: 'テスト01',
//     user_type_code: '11',
//     // 無償ユーザー：'0', 有償ユーザー：'1'
//     user_permission: '1',
//     // 一般ユーザー：'0', 管理者ユーザー：'1'
//     admin_permission: '1'
//   }
// };


// const financial = {
//   userCredential: {
//     company_id: 'DELETE_TEST_F01',
//     // company_id: '3c3a677d-0b84-47c1-be92-c79ab3595727',
//     company_name: '削除テスト金融01',
//     user_id: 'delete_test_01',
//     user_name: '削除用 テスト01',
//     family_name: '削除用',
//     given_name: 'テスト01',
//     user_type_code: '20',
//     // 無償ユーザー：'0', 有償ユーザー：'1'
//     user_permission: '2',
//     // 一般ユーザー：'0', 管理者ユーザー：'1'
//     admin_permission: '0'
//   }
// };