import { useAuth0 } from "@auth0/auth0-react";

/**
 * バックエンドAPIHooks
 * @returns
 */
const useApi = () => {

  const { getAccessTokenSilently } = useAuth0();

  /**
   * ユーザー情報取得
   * @param {*} params
   * @param {Object} params.company_id 顧客ID(認証基盤)
   * @returns
   */
  const getUserInfo = async (params) => {
    return await request('get_user_info', 'POST', params);
  }

  /**
   * マップ表示用拠点取得
   * @param {Object} params
   * @param {Object} params.company_id 顧客ID(認証基盤)
   * @returns  {Promise}
   */
  const getLocationMapData = async (params) => {
    return await request('get_location_map_data', 'POST', params);
  }

  /**
   * 入力セッション管理
   * @param {Object} params
   * @param {string} params.user_id ユーザーID(認証基盤)
   * @returns {Promise<{SessionID: string}>} SessionId セッションID
   */
  const createPurchasedSession = async (params) => {
    return await request('create_purchased_session', 'POST', params);
  }

  /**
   * 拠点仮データ登録
   * @param {Object} params
   * @param {string} params.user_permission 利用権限
   * @param {string} params.company_id 顧客ID(認証基盤)
   * @param {string} params.company_name 顧客名(認証基盤)
   * @param {string} params.user_id ユーザーID(認証基盤)
   * @param {string} params.family_name ユーザー名字(認証基盤)
   * @param {string} params.given_name ユーザー名前(認証基盤)
   * @param {string} params.purchased_session 拠点情報入力セッションID
   * @param {File} params.upload_file 拠点入力CSVファイル
   * @returns {Promise<{count: number, price: number, start_date: string, end_date: string, locations: Array}>
   * }
   */
  const createLocation = async (params) => {
    const formData = new FormData();

    for(let p in params){
      formData.append(p, params[p]);
    }

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${process.env.REACT_APP_API_URL}${'create_location'}/`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error();
      }

      return await response.json();

    } catch (error) {
      throw error;
    }
  }

  /**
   * 拠点仮データ更新
   * @param {Object} params
   * @param {string} params.purchased_session 拠点情報入力セッションID
   * @param {Array} params.locations 拠点情報配列
   * @param {string} params.locations[].location_id 拠点ID
   * @param {number} params.locations[].location_lat 拠点緯度
   * @param {number} params.locations[].location_lon 拠点経度
   */
  const updateLocation = async(params) => {
    return await request('update_location', 'POST', params);
  }

  /**
   * 拠点仮データ削除
   * @param {Object} params
   * @param {string} params.purchased_session 拠点情報入力セッションID
   */
  const deleteLocation = async(params) => {
    return await request('delete_location', 'POST', params);
  }

  /**
   * 拠点分析
   * @param {Object} params
   * @param {string} params.user_permission 利用権限
   * @param {string} params.company_id 顧客ID(認証基盤)
   * @param {string} params.user_id ユーザーID(認証基盤)
   * @param {string} params.purchased_session 拠点情報入力セッションID
   */
  const analysisLocation = async(params) => {
    return await request('analysis_location', 'POST', params);
  }

  /**
   * メール送信
   * @param {Object} params
   * @param {string} params.purchased_date  購入日
   * @param {number} params.purchased_count 購入数
   * @param {string} params.analysis_id Analysis ID
   */
  const sendMail = async(params) => {
    return await request('send_mail', 'POST', params);
  }

  /**
   * 拠点分析結果検索
   * @param {*} params
   * @returns
   */
  const searchAnalysisResult = async (params) => {
    return await request('search_analysis_result', 'POST', params);
  }

  /**
   * AnalysisID一覧取得
   * @param {*} params
   * @returns
   */
  const getAnalysisIdList = async (params) => {
    return await request('get_analysis_id_list', 'POST', params);
  };

  /**
   * Location Name一覧取得
   * @param {*} params
   * @returns
   */
  const getLocationNameList = async (params) => {
    return await request('get_location_name_list', 'POST', params);
  };

  /**
   * Group ID一覧取得
   * @param {*} params
   * @returns
   */
  const getGroupIdList = async (params) => {
    return await request('get_group_id_list', 'POST', params);
  };

  /**
   * データ登録ジョブ進捗管理
   * @param {Object} params
   * @param {string} params.user_id ユーザーID(認証基盤)
   * @param {string} params.company_id 顧客ID(認証基盤)
   * @returns {Promise}
   */
  const getInsertStatus = async (params) => {
    return await request('get_insert_status', 'POST', params);
  }

  /**
   * データ分析ジョブ進捗管理
   * @param {Object} params
   * @param {string} params.user_id ユーザーID(認証基盤)
   * @param {string} params.company_id 顧客ID(認証基盤)
   * @returns {Promise}
   */
  const getAnalysisStatus = async (params) => {
    return await request('get_analysis_status', 'POST', params);
  }

  /**
   * 入力データ地図プレビュー表示
   * @param {string} params.purchased_session 拠点情報入力セッションID
   */
  const getPreviewData = async (params) => {
    return await request('get_preview_data', 'POST', params);
  }

  /**
   * 拠点仮データ再登録
   * @param {Object} params
   * @param {string} params.purchased_session 拠点情報入力セッションID
   * @param {File} params.upload_file 拠点入力CSVファイル
   * @returns {Promise<{count: number, price: number, start_date: string, end_date: string, locations: Array}>
   * }
   */
  const createFixedLocation = async (params) => {
    const formData = new FormData();

    for (let p in params) {
      formData.append(p, params[p]);
    }

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${process.env.REACT_APP_API_URL}${'create_fixed_location'}/`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: formData
      });

      if (!response.ok) {
        throw new Error();
      }

      return await response.json();
    } catch (error) {
      throw error;
    }
  };

  /**
   * ジョブステータス更新
   * @param {string} params.purchased_session 拠点情報入力セッションID
   * @param {string} params.job_code ジョブカテゴリ（1: データインポート、2: ジオコーディング、3: 分析処理）
   * @returns {Promise}
   */
  const updateJobStatus = async(params) => {
    return await request('update_job_status', 'POST', params);
  }
  
  /**
   * 拠点データ削除
   * @param {Object} params
   * @param {string} params.do_delete 「0」：削除しない（件数取得のみ）「1」：削除する
   * @param {string} params.user_permission 利用権限
   * @param {string} params.company_id 顧客ID(認証基盤)
   * @param {Array} params.analysis_id_list Analysis ID配列
   * @param {Array} params.location_name_list 拠点名配列
   * @param {Array} params.group_id_list グループID配列
   * @returns {Promise}
   */
  const deletePurchasedLocation = async (params) => {
    return await request('delete_purchased_location', 'POST', params);
  };

  const downloadCsv = async (params) => {
    let response;
    try {
      const token = await getAccessTokenSilently();
      response = await fetch(`${process.env.REACT_APP_API_URL}download_csv/`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(params)
      });

      if (!response.ok) {
        throw new Error();
      }
      
    } catch (error) {
      throw error;
    }

    try {
      if (response.headers.get('content-type').includes('application/json')) {
        const result = await response.json();
        if (result.download_err_message) {
          throw result.download_err_message;
        }
      }
    } catch (error) {
      throw error;
    }

    const blob = await response.blob();
    // BlobデータからURLを生成
    const url = window.URL.createObjectURL(blob);

    // ダウンロードリンクを生成
    const a = document.createElement('a');
    a.href = url;
    a.download = `result_${params.download_result}.zip`; // ダウンロード時のファイル名を指定
    document.body.appendChild(a);

    // リンクをクリックしてダウンロードを開始
    a.click();

    // 不要になったURLを解放
    window.URL.revokeObjectURL(url);
  };

  const downloadPdf = async(params) => {
    let response;
    try {
      const token = await getAccessTokenSilently();
      response = await fetch(`${process.env.REACT_APP_API_URL}download_pdf/`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(params)
      });

      if (!response.ok) {
        throw new Error();
      }

    } catch (error) {
      throw error;
    }

    const blob = await response.blob();
    // BlobデータからURLを生成
    const url = window.URL.createObjectURL(blob);

    // ダウンロードリンクを生成
    const a = document.createElement('a');
    a.href = url;
    a.download = 'result.pdf'; // ダウンロード時のファイル名を指定
    document.body.appendChild(a);

    // リンクをクリックしてダウンロードを開始
    a.click();

    // 不要になったURLを解放
    window.URL.revokeObjectURL(url);
  }

  /**
   * ジオコーディングエラーファイルダウンロード
   * @param {Object} params
   * @param {string} params.purchased_session 拠点情報入力セッションID
   */
  const downloadErrorGeocode = async (params) => {
    let response;
    try {
      const token = await getAccessTokenSilently();
      response = await fetch(`${process.env.REACT_APP_API_URL}download_error_geocode/`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(params)
      });

      if (!response.ok) {
        throw new Error();
      }

    } catch (error) {
      throw error;
    }

    const blob = await response.blob();
    // BlobデータからURLを生成
    const url = window.URL.createObjectURL(blob);

    // ダウンロードリンクを生成
    const a = document.createElement('a');
    a.href = url;
    a.download = 'error_geocoding.csv'; // ダウンロード時のファイル名を指定
    document.body.appendChild(a);

    // リンクをクリックしてダウンロードを開始
    a.click();

    // 不要になったURLを解放
    window.URL.revokeObjectURL(url);
  };


  /**
   * 署名付きURLの取得
   * @param {string} ファイル名
   * @returns
   */
  const generateSignedUrl = async (fileName) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${process.env.REACT_APP_API_URL}generate_signed_url/${fileName}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        },
      });

      if (!response.ok) {
        throw new Error();
      }

      return await response.json();

    } catch (error) {
      throw error;
    }
  };


  /**
   * リクエスト実行
   * @param {*} url
   * @param {*} params
   * @returns
   */
  const request = async (url, method, params) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${process.env.REACT_APP_API_URL}${url}/`, {
        method: method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(params)
      });

      if (!response.ok) {
        throw new Error();
      }

      return await response.json();

    } catch (error) {
      throw error;
    }
  }

  return {
    getUserInfo,
    getLocationMapData,
    createPurchasedSession,
    createLocation,
    updateLocation,
    deleteLocation,
    analysisLocation,
    sendMail,
    searchAnalysisResult,
    getAnalysisIdList,
    getLocationNameList,
    getGroupIdList,
    getInsertStatus,
    getAnalysisStatus,
    getPreviewData,
    createFixedLocation,
    updateJobStatus,
    deletePurchasedLocation,
    downloadCsv,
    downloadPdf,
    downloadErrorGeocode,
    generateSignedUrl
  };
}

export default useApi;